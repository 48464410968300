import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import DocDetail from "../../../components/Drive/ESign/detail.js"
import ReduxWrapper from "../../../redux/reduxWrapper"

const ESignDetails = props => {
  return <PrivateRoute component={DocDetail} location={props.location} selectedDocId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<ESignDetails {...props} />} />
export default WrappedPage
