import * as React from "react"

const ImageViewer = ({ legacyEditorRef }) => {
  return (
    <div className="col-lg-12 mb-3" id="editor_panel">
      <div className="accordion" id="editorPanelAccordion">
        <div className="accordion-item">
          <div className="accordion-body">
            <div className="row">
              <iframe height="1400" id="eSignPDF" ref={legacyEditorRef} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageViewer
